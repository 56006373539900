<template>
  <v-card>
    <v-card-title class="primary white--text">
      <v-icon color="white">fas fa-cogs</v-icon>
      <h6 class="ml-3 text-h6">Quantos itens foram produzidos?</h6>
    </v-card-title>
    <v-card-text
      class="mt-7"
      v-if="volumes"
    >
      <div
        class="mb-7 d-flex justify-space-between"
        v-once
      >
        <span class="text-subtitle-2 px-4 pb-4"
          >Qtd. Total:
          {{ item.alt_qtty ? item.alt_qtty : item.default_qtty }}</span
        >
        <span class="text-subtitle-2 px-4 pb-4"
          >Produzidos: {{ produced_qtty }}</span
        >
        <span class="text-subtitle-2 px-4 pb-4"
          >Qtd. Restante: {{ rest_qtty }}</span
        >
      </div>

      <h5 class="text-center text-h5">Volumes</h5>
      <v-sheet
        v-for="(item, idx) in volumes"
        :key="idx"
        class="grey lighten-3 pa-7 mb-7 mt-3"
        style="position: relative"
        rounded="lg"
      >
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              v-model.number="item.quantity"
              label="Quantidade"
              v-mask="['########']"
              rounded
              outlined
              hide-details
              dense
              :disabled="item.id"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.number="item.weight"
              label="Peso Kg"
              v-mask="['##.##', '###.##', '####.##']"
              rounded
              outlined
              hide-details
              dense
              :disabled="item.id"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model.number="item.type"
              :items="types"
              item-value="id"
              item-text="label"
              label="Tipo"
              rounded
              outlined
              hide-details
              dense
              :disabled="item.id"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.number="item.volume"
              label="Volume"
              rounded
              outlined
              hide-details
              dense
              :disabled="item.id"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.number="item.height"
              label="Altura"
              rounded
              outlined
              hide-details
              dense
              :disabled="item.id"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.number="item.width"
              label="Largura"
              rounded
              outlined
              hide-details
              dense
              :disabled="item.id"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.number="item.volume_length"
              label="Comprimento"
              rounded
              outlined
              hide-details
              dense
              :disabled="item.id"
            />
          </v-col>
        </v-row>
        <v-btn
          v-show="!item.id"
          @click="removeVolume(idx)"
          class="error"
          fab
          absolute
          top
          right
          icon
          x-small
        >
          <v-icon
            color="white"
            x-small
            >fas fa-times</v-icon
          >
        </v-btn>
      </v-sheet>

      <h6
        v-if="volumes.length == 0"
        class="text-subtitle-1 text-center grey--text text--lighten-1"
      >
        Nenhum volume adicionado
      </h6>

      <div
        v-if="!readOnly"
        class="text-center pa-4 mb-2"
      >
        <v-btn
          @click="addVolume"
          color="primary"
          rounded
          small
        >
          Adicionar volume
          <v-icon
            right
            small
            >fas fa-plus</v-icon
          >
        </v-btn>
      </div>

      <v-row dense>
        <v-col cols="6">
          <v-text-field
            v-model="item.checker_name"
            label="Conferente"
            rounded
            outlined
            hide-details
            dense
            :disabled="readOnly"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="item.date_field"
            label="Data"
            v-mask="['##/##/####']"
            rounded
            outlined
            hide-details
            dense
            :disabled="readOnly"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="item.defects"
            label="Defeitos"
            rounded
            outlined
            hide-details
            dense
            :disabled="readOnly"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="item.samples"
            label="Amostras"
            rounded
            outlined
            hide-details
            dense
            :disabled="readOnly"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="item.observations"
            label="Observação"
            auto-grow
            rounded
            outlined
            hide-details
            dense
            :disabled="readOnly"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="$emit('closeDialog')"
        color="primary"
        rounded
        outlined
        >Cancelar</v-btn
      >
      <v-btn
        v-if="!readOnly"
        @click="save"
        :disabled="to_produce_qtty == 0"
        color="primary"
        rounded
        >Salvar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    readOnly: Boolean,
  },
  data() {
    return {
      errMsg: null,
      to_produce_qtty: null,
      volumes: [],
      types: [
        { label: 'Caixa', id: 1 },
        { label: 'Pacote', id: 2 },
        { label: 'Rolo', id: 3 },
        { label: 'Saco', id: 4 },
        { label: 'Envelope', id: 5 },
        { label: 'Pallete', id: 6 },
      ],
    }
  },
  computed: {
    produced_qtty: function () {
      return this.volumes.reduce((acc, curr) => acc + curr.quantity, 0)
    },
    rest_qtty: {
      get: function () {
        if (this.item) {
          return this.item.alt_qtty
            ? this.item.alt_qtty - this.produced_qtty
            : this.item.default_qtty - this.produced_qtty
        }
        return ''
      },
      set: function (newvalue) {
        this.to_produce_qtty = newvalue
      },
    },
  },
  methods: {
    save() {
      let payload = {
        id: this.item.id,
        produced_qtty: this.item.produced_qtty + this.to_produce_qtty,
        order_id: this.item.order_id,
        weight: this.item.weight,
        defects: this.item.defects,
        checker_name: this.item.checker_name,
        date_field: this.item.date_field,
        observations: this.item.observations,
        samples: this.item.samples,
        volumes: this.volumes,
      }

      this.$api
        .patch('orders/products/production', payload)
        .then((res) => {
          this.item.order_product_status = res.data.product.order_product_status
          this.item.alt_qtty = res.data.product.alt_qtty
          this.item.default_qtty = res.data.product.default_qtty

          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Produto atualizado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', { show: false })
            },
          })
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.$emit('closeDialog')
        })
    },
    getVolumes() {
      this.$api
        .get(`orders/products/volumes/${this.item.id}`)
        .then((res) => {
          this.volumes = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    addVolume() {
      this.volumes.push({})
    },
    removeVolume(idx) {
      this.volumes.splice(idx, 1)
    },
  },
  mounted() {
    this.getVolumes()
    this.to_produce_qtty = this.rest_qtty
  },
}
</script>
